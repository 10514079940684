function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React from 'react';
/**
 * Header component below the methone bar.
 * 
 * Saw this was implemented on some pages, why not create a reusable one.
 */

var Header = function Header(_ref) {
  var _ref$title = _ref.title,
      title = _ref$title === void 0 ? "" : _ref$title,
      _ref$action = _ref.action,
      action = _ref$action === void 0 ? undefined : _ref$action,
      rest = _objectWithoutProperties(_ref, ["title", "action"]);

  return React.createElement("header", null, React.createElement("div", {
    className: "header-inner"
  }, React.createElement("div", {
    className: "row"
  }, React.createElement("div", {
    className: "header-left col-md-2"
  }, rest.children), React.createElement("div", {
    className: "col-md-8"
  }, React.createElement("h2", null, title)), React.createElement("div", {
    className: "header-right col-md-2"
  }, action && React.createElement("button", {
    className: "primary-action",
    onClick: action.onClick
  }, action.text)))));
};

export default Header;